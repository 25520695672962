const ProjectHeader = ({ projectTitle, description }) => {
    return (
        <section id="introduction" className="introduction section">
            <div className="introduction-container container">
                <div className="columns">
                    <div className="column is-12">
                        <div
                            className="content"
                            style={{ textAlign: "center" }}
                        >
                            <h3 style={{ color: "#e1e1e1" }}>Welcome to</h3>
                            <h1 className="title">
                                <span className="blue-text">
                                    {projectTitle}
                                </span>
                            </h1>
                            <p className="description">{description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectHeader;
