import React from "react";
import ProjectCard from "../ProjectCard";

const Projects = ({ projectData }) => {
    const numProjects = projectData.length;
    const numColumns = Math.ceil(numProjects / 3);

    const columnsArray = [];
    for (let i = 0; i < numColumns; i++) {
        const columnStartIndex = i * 3;
        const columnProjects = projectData.slice(
            columnStartIndex,
            columnStartIndex + 3
        );

        columnsArray.push(
            <div className="columns" key={i}>
                {columnProjects.map((project, index) => (
                    <div
                        className="column is-multiline is-4"
                        key={index}
                        style={{ cursor: "pointer" }}
                    >
                        <ProjectCard
                            day={project.day}
                            title={project.title}
                            img={project.img}
                            url={project.url}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <section id="projects" className="about section">
            <div
                className="projects-container container"
                style={{ cursor: "pointer" }}
            >
                <h1 className="title">Projects</h1>
                <br />
                <div className="fadeInBottom">{columnsArray}</div>
            </div>
        </section>
    );
};

export default Projects;
