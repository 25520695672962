import React from "react";

const ImageCard = ({ img, title, date }) => {
    return (
        <span className="portfolio-item d-block">
            <div className="foto foto-dark-hover project">
                <div className="project-img">
                    <img
                        src={img}
                        alt="image"
                        style={{
                            marginBottom: 0,
                            paddingBottom: 0,
                            position: "relative",
                        }}
                    />
                    <span className="project-date">{date}</span>
                    <br />

                    <p className="project-title-settings mt-3">{title}</p>
                </div>
            </div>
        </span>
    );
};

export default ImageCard;
