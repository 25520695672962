import React from "react";

const Footer = () => {
	return (
		<footer style={{ height: "1000" }}>
			<div className="col-md-12">
				<div className="social-links"></div>

				<div className="copyright py-5 text-center">
					<div className="container" style={{ textAlign: "center" }}>
						<small style={{ color: "gray" }}>Website by Ann Song</small>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
