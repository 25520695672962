import React from "react";
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Badge from "react-bootstrap/Badge";
import { useState, useEffect } from "react";
import { FaJava } from "react-icons/fa";

const Experience = ({ resumeExperience, resumeBasicInfo }) => {
	const [sectionName, setSectionName] = useState("");
	const [work, setWork] = useState([]);

	useEffect(() => {
		if (resumeExperience && resumeBasicInfo) {
			setSectionName(resumeBasicInfo.section_name.experience);

			const workData = resumeExperience.map((work, i) => {
				const technologies = work.technologies;
				const mainTechnologies = work.mainTech;

				const mainTech = mainTechnologies.map((technology, i) => (
					<Badge pill className="main-badge mr-2 mb-2" key={i}>
						{technology}
					</Badge>
				));

				const tech = technologies.map((technology, i) => (
					<Badge pill className="experience-badge mr-2 mb-2" key={i}>
						{technology}
					</Badge>
				));

				return (
					<VerticalTimelineElement
						className="vertical-timeline-element--work"
						date={work.years}
						iconStyle={{
							background: "#AE944F",
							color: "#fff",
							textAlign: "center",
						}}
						icon={<FaJava />}
						key={i}
					>
						<div style={{ textAlign: "left", marginBottom: "4px" }}>
							{mainTech}
						</div>

						<h3
							className="vertical-timeline-element-title"
							style={{ textAlign: "left" }}
						>
							{work.title}
						</h3>
						<h4
							className="vertical-timeline-element-subtitle"
							style={{ textAlign: "left" }}
						>
							{work.company}
						</h4>
						<div style={{ textAlign: "left", marginTop: "15px" }}>{tech}</div>
					</VerticalTimelineElement>
				);
			});

			setWork(workData);
		}
	}, [resumeExperience, resumeBasicInfo]);

	return (
		<section id="resume" className="experience section pb-5">
			<div className="experience-container container">
				<h1 className="title">Experience</h1>
				<div className="col-md-8 mx-auto">
					<VerticalTimeline>{work}</VerticalTimeline>
				</div>
			</div>
		</section>
	);
};

export default Experience;
