import React from "react";
import { BsGithub, BsLinkedin, BsInstagram, BsDiscord } from "react-icons/bs";

const HeaderSocials = () => {
	return (
		<>
			<a
				href="https://github.com/gmsong06"
				target="_blank"
				className="button btn-alt header-social"
			>
				<BsGithub />
			</a>
			<a
				href="https://discord.com/users/546131088356016139"
				target="_blank"
				className="button btn-alt header-social"
			>
				<BsDiscord />
			</a>
			<a
				href="https://www.linkedin.com/in/ann-song-0755a222a/"
				target="_blank"
				className="button btn-alt header-social"
			>
				<BsLinkedin />
			</a>
			<a
				href="https://www.instagram.com/ann.songg/"
				target="_blank"
				className="button btn-alt header-social"
			>
				<BsInstagram />
			</a>
		</>
	);
};

export default HeaderSocials;
