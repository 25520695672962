import React from "react";
// import "./ProjectInfo.css"; // Import the CSS file for styling

const ProjectInfo = ({ descriptionContent }) => {
    // Using map to generate JSX elements
    const descriptionContentList = descriptionContent.map((content, index) => (
        <p>{content}</p>
    ));
    return (
        <section id="introduction" className="introduction section">
            <div className="introduction-container container">
                <div className="columns">
                    <div
                        className="column is-12 animated fadeInBottom"
                        style={{ textAlign: "center" }}
                    >
                        <h1 className="title" style={{ marginTop: "-3%" }}>
                            <span style={{ color: "#e1e1e1" }}>
                                DESCRIPTION
                            </span>
                        </h1>
                        <div className="content" style={{ color: "white" }}>
                            {descriptionContentList}
                        </div>
                    </div>
                    {/* <div
                        className="column is-6 animated fadeInRight"
                        style={{ textAlign: "center" }}
                    >
                        <h1 className="title">
                            <span style={{ color: "#e1e1e1" }}>Quick Info</span>
                        </h1>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

export default ProjectInfo;
