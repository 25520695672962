import sorostem from "../../assets/sorostem.png";
import AD from "../../assets/ad.jpg";
import HealersOfDestruction from "../../assets/generativeArt/HealersOfDestruction.png";
import Courses from "../../assets/courses.png";
import Android from "../../assets/android.jpg";

import Navbar from "../Navbar";
import Header from "../homeSections/Header";
import AboutMe from "../homeSections/AboutMe";
import Projects from "../homeSections/Projects";
import Experience from "../homeSections/Experience";
import Contact from "../homeSections/Contact";
import Footer from "../homeSections/Footer";

const Home = () => {
    const projectData = [
        {
            day: "November 2023",
            title: "GENERATIVE ART",
            img: HealersOfDestruction,
            url: "https://www.annsong.me/generative-art/",
        },
        // {
        //     day: "July 2023",
        //     title: "CS COURSES",
        //     img: Courses,
        //     url: "https://www.annsong.me/courses/",
        // },
        {
            day: "October 2022",
            title: "VULNERABILITIES IN ANDROID APPS",
            img: Android,
            url: "https://arxiv.org/abs/2310.14137",
        },
        {
            day: "June 2022",
            title: "ALZHEIMER'S DISEASE ML RESEARCH",
            img: AD,
            url: "https://www.jsr.org/hs/index.php/path/article/view/4347",
        },
        // {
        //     day: "May 2022",
        //     title: "SOROSTEM",
        //     img: sorostem,
        //     url: "https://www.sorostem.com/",
        // },
    ];

    const resumeExperience = [
        {
            years: "August 2023 - Present",
            title: "Chief Technology Officer, Project Pathways Director",
            company: "Youth Research Initiative",
            technologies: ["Python"],
            mainTech: ["Python"],
        },
        {
            years: "July 2023 - Present",
            title: "Lead Instructor, Course Creator",
            company: "Robotics and Beyond",
            technologies: ["Java", "Python", "Scratch"],
            mainTech: ["Java"],
        },
        {
            years: "June 2022 - Present",
            title: "VEX IQ Coach and Teaching Assistant",
            company: "CT STEM",
            technologies: ["VEX IQ", "Scratch"],
            mainTech: ["VEX IQ"],
        },
        {
            years: "October 2021 - Present",
            title: "Computer Science Instructor",
            company: "KTBYTE Computer Science Academy",
            technologies: ["Processing", "Java"],
            mainTech: ["Java"],
        },
    ];

    const resumeBasicInfo = {
        section_name: {
            experience: "Experience",
        },
    };
    return (
        <div className="app">
            <Navbar />
            <Header />
            <AboutMe />
            <Projects projectData={projectData} />;
            <Experience
                resumeExperience={resumeExperience}
                resumeBasicInfo={resumeBasicInfo}
            />
            <Contact />
            <Footer />
        </div>
    );
};

export default Home;
