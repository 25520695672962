import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import GenerativeArt from "./components/pages/GenerativeArt";
import Home from "./components/pages/Home";

const App = () => {
    return (
        <div className="app">
            <Router basename={"/"}>
                <Routes>
                    <Route exact path="/" Component={Home}></Route>
                    <Route
                        exact
                        path="/generative-art"
                        Component={GenerativeArt}
                    ></Route>
                </Routes>
            </Router>
        </div>
    );
};

export default App;
