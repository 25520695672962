import React from "react";
import ImageCard from "../projectSections/ImageCard";

const Images = ({ imageData }) => {
    const numImages = imageData.length;
    const numColumns = Math.ceil(numImages / 3);

    const columnsArray = [];
    for (let i = 0; i < numColumns; i++) {
        const columnStartIndex = i * 3;
        const columnImages = imageData.slice(
            columnStartIndex,
            columnStartIndex + 3
        );

        columnsArray.push(
            <div className="columns" key={i}>
                {columnImages.map((image, index) => (
                    <div
                        className="column is-multiline is-4"
                        key={index}
                        style={{ cursor: "pointer" }}
                    >
                        <ImageCard
                            img={image.img}
                            date={image.date}
                            title={image.title}
                        />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <section id="projects" className="about section ">
            <div
                className="projects-container container "
                style={{ cursor: "pointer" }}
            >
                <h1 className="title">Images</h1>
                <br />
                <div className="fadeInBottom">{columnsArray}</div>
            </div>
        </section>
    );
};

export default Images;
