import React from "react";

const ProjectCard = ({ day, title, img, url }) => {
    const content = (
        <span className="portfolio-item d-block">
            <div className="foto foto-dark-hover project">
                <div className="project-img">
                    <img
                        src={img}
                        alt="projectImages"
                        style={{
                            marginBottom: 0,
                            paddingBottom: 0,
                            position: "relative",
                        }}
                    />
                    <span className="project-date">{day}</span>
                    <br />

                    <p className="project-title-settings mt-3">{title}</p>
                </div>
            </div>
        </span>
    );

    return url ? (
        <a href={url} target="_blank">
            {content}
        </a>
    ) : (
        content
    );
};

export default ProjectCard;
