import Navbar from "../Navbar";
import Footer from "../homeSections/Footer";
import ProjectHeader from "../projectSections/ProjectHeader";
import ProjectInfo from "../projectSections/ProjectInfo";
import ImageGallery from "../projectSections/ImageGallery";

import TheShineOfAutumn from "../../assets/generativeArt/TheShineOfAutumn.png";
import TheRuler from "../../assets/generativeArt/TheRuler.png";
import HealersOfDestruction from "../../assets/generativeArt/HealersOfDestruction.png";

const GenerativeArt = () => {
    const imageData = [
        {
            img: HealersOfDestruction,
            date: "November 2023",
            title: "Healers of Destruction",
        },
        {
            img: TheShineOfAutumn,
            date: "November 2023",
            title: "The Shine of Autumn",
        },
        {
            img: TheRuler,
            date: "November 2023",
            title: "The Ruler",
        },
    ];
    return (
        <div className="app">
            <Navbar />
            <ProjectHeader
                projectTitle={"Generative Art"}
                description={"by Ann Song"}
            />
            <ProjectInfo
                descriptionContent={[
                    "As a child, I used to love spending hours in front of a canvas, painting and drawing whatever I wanted. It was one of my favorite pastimes and still is. However, as I’ve grown older, I’ve discovered new interests like programming and robotics. Particularly, I enjoyed thinking algorithmically and definitively about problems. I never had time for art anymore and although I still enjoyed it, I loved my new interests more. I never thought I’d be able to participate in both sides of myself at the same time.",
                    "That changed when I stumbled upon generative art, the realm of algorithmically crafting unique pieces of artwork. It became the ideal way for me to express myself creatively while also thinking critically about problems I wanted to solve within my art.",
                ]}
            />
            <ImageGallery imageData={imageData} />
            <Footer />
        </div>
    );
};

export default GenerativeArt;
