import CV from "../../assets/CV.pdf";

const Header = () => {
    return (
        <section id="introduction" className="introduction section">
            <div className="introduction-container container">
                <div className="columns">
                    <div className="column is-12">
                        <div
                            className="content"
                            style={{ textAlign: "center" }}
                        >
                            <h3 style={{ color: "#e1e1e1" }}>Hey! I'm</h3>
                            <h1 className="title">
                                <span className="blue-text">Ann Song</span>
                            </h1>
                            <p className="description">
                                Student at Choate Rosemary Hall
                            </p>
                            <a
                                href={CV}
                                target="_blank"
                                className="button btn btn-resume"
                            >
                                Resume
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;
