import React from "react";
import "../../styles/contact.css";
import { MdEmail } from "react-icons/md";
import { AiFillPhone, AiFillLinkedin } from "react-icons/ai";

const Contact = () => {
	return (
		<section id="contact" className="about section">
			<div className="contact-container container">
				<h1 className="title">Contact</h1>
				<br />
				<br />
				<div
					style={{ position: "relative", left: "125px" }}
					className="columns"
				>
					<div className="column is-4">
						<MdEmail className="contact-icon" />
						<h5 className="contact-type">gmsong1978@gmail.com</h5>
					</div>
					<div className="column is-4">
						<AiFillPhone className="contact-icon" />
						<h5 className="contact-type">860-276-4364</h5>
					</div>
					<div className="column is-4">
						<AiFillLinkedin className="contact-icon" />
						<h5 className="contact-type">
							<a
								href="https://www.linkedin.com/in/ann-song-0755a222a/"
								target="_blank"
								className="contact-type"
							>
								Ann Song
							</a>
						</h5>
					</div>
				</div>
				<div className="contact-types"></div>
			</div>
		</section>
	);
};

export default Contact;
