import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const Navbar = () => {
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    const isHome = location.pathname === "/";

    return (
        <nav className="navbar is-fixed-top" role="navigation">
            <div className="navbar-brand">
                <a
                    onClick={() => {
                        setIsActive(!isActive);
                    }}
                    role="button"
                    className={`navbar-burger burger ${
                        isActive ? "is-active" : ""
                    }`}
                    aria-label="menu"
                    aria-expanded="false"
                    data-target="navbar-menu"
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div
                id="navbar-menu"
                className={`navbar-menu ${isActive ? "is-active" : ""}`}
            >
                <div className="navbar-start">
                    {/* <RouterLink to="/" className="navbar-item">
                        Home
                    </RouterLink>
                    {isHome ? (
                        <>
                            <ScrollLink
                                to="about"
                                smooth={true}
                                className="navbar-item"
                            >
                                About
                            </ScrollLink>
                            <ScrollLink
                                to="projects"
                                smooth={true}
                                className="navbar-item"
                            >
                                Projects
                            </ScrollLink>
                            <ScrollLink
                                to="experience"
                                smooth={true}
                                className="navbar-item"
                            >
                                Experience
                            </ScrollLink>
                            <ScrollLink
                                to="contact"
                                smooth={true}
                                className="navbar-item"
                            >
                                Contact
                            </ScrollLink>
                        </>
                    ) : (
                        <></>
                    )} */}
                    <ScrollLink
                        to="introduction"
                        smooth={true}
                        className="navbar-item"
                    >
                        Home
                    </ScrollLink>
                    <ScrollLink
                        to="about"
                        smooth={true}
                        className="navbar-item"
                    >
                        About
                    </ScrollLink>
                    <ScrollLink
                        to="projects"
                        smooth={true}
                        className="navbar-item"
                    >
                        Projects
                    </ScrollLink>
                    <ScrollLink
                        to="experience"
                        smooth={true}
                        className="navbar-item"
                    >
                        Experience
                    </ScrollLink>
                    <ScrollLink
                        to="contact"
                        smooth={true}
                        className="navbar-item"
                    >
                        Contact
                    </ScrollLink>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
